import React from "react";
import { Tooltip } from "@mui/material";

export default function ClientLogo({ client }) {
	const path =
		process.env.PUBLIC_URL + "images/clients/" + client.slug + ".svg";

	return (
		<Tooltip placement="top" arrow title={client.title}>
			<a href={client.website} rel="noreferrer" target="_blank">
				<img src={path} alt={client.title} title={client.title} />
			</a>
		</Tooltip>
	);
}
