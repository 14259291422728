import React from "react";
// import classNames from "classnames/bind";
// import { Tooltip } from "@mui/material";
import bgVideo from "../assets/video/bg.mov";
import Header from "../components/Header.tsx";
import OnLoadAnimator from "../components/OnLoadAnimator.tsx";
import ClientRoll from "../components/ClientRoll.tsx";
// import joeAvatar from "../assets/images/joe-avatar.png";

export default function JoinPage() {
	return (
		<>
			<OnLoadAnimator
				order={3}
				content={
					<div className="bgs">
						<video autoPlay muted loop>
							<source src={bgVideo} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				}
			/>
			<div id="page-content" className="join">
				<OnLoadAnimator order={1} content={<Header />} />
				<div className="splash">
					<OnLoadAnimator
						order={2}
						content={
							<iframe
								title={"Airtable Form"}
								className={"airtable-embed airtable-dynamic-height"}
								src={
									"https://airtable.com/embed/shr9gTsKhxFWkOgLK?backgroundColor=yellow"
								}
								frameBorder={"0"}
								width={"100%"}
								height={"3033"}
							></iframe>
						}
					/>
				</div>
				<OnLoadAnimator order={4} content={<ClientRoll />} />
			</div>
			<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
		</>
	);
}
