import React from "react";
import Obfuscate from "react-obfuscate";
import { Link } from "react-router-dom";
// import classNames from "classnames/bind";
import { ReactComponent as LogoHorizontal } from "../assets/images/brand/logo-full-horizontal.svg";
import OnLoadAnimator from "./OnLoadAnimator.tsx";

export default function Header({ order, content }) {
	return (
		<header className="primary">
			<OnLoadAnimator
				order={1}
				content={
					<Link to="/">
						<LogoHorizontal className="header-logo" />
					</Link>
				}
			/>

			<nav>
				<OnLoadAnimator
					order={2}
					content={
						<a target="_blank" href={"https://book.morgen.so/stasio/trait"}>
							Schedule a Meeting
						</a>
					}
				/>
				<OnLoadAnimator
					order={3}
					content={<Link to={"/join-us"}>Join The Team</Link>}
				/>
				<OnLoadAnimator
					order={4}
					content={
						<Obfuscate
							email="joe@trait.media"
							aria-label="Contact Us"
							className="button-style"
							headers={{
								subject: "I'd love to work with you!",
							}}
						>
							Contact Us
						</Obfuscate>
					}
				/>
			</nav>
		</header>
	);
}
