import React from "react";
// import classNames from "classnames/bind";
// import { Tooltip } from "@mui/material";
import bgVideo from "../assets/video/bg.mov";
import Header from "../components/Header.tsx";
import OnLoadAnimator from "../components/OnLoadAnimator.tsx";
import ClientRoll from "../components/ClientRoll.tsx";
// import joeAvatar from "../assets/images/joe-avatar.png";

export default function HomePage() {
	return (
		<>
			<OnLoadAnimator
				order={3}
				content={
					<div className="bgs">
						<video autoPlay muted loop>
							<source src={bgVideo} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				}
			/>
			<div id="page-content" className="home">
				<OnLoadAnimator order={1} content={<Header />} />
				<div className="splash">
					<OnLoadAnimator
						order={2}
						content={
							<p className="big">
								Based in New Jersey,{" "}
								<mark>
									trait is a creative agency with decades of experience
								</mark>{" "}
								in generating top-shelf sales, marketing, and social collateral.
							</p>
						}
					/>
				</div>

				<OnLoadAnimator order={4} content={<ClientRoll />} />
			</div>
		</>
	);
}
