import React from "react";

export default function EmailSignatureTemplate({
	personName,
	personEmail,
	personTitle,
	personPhone,
	personAvatar,
}) {
	const absPath = "https://trait.media/images/email-signature/";
	return (
		<>
			<br />
			<table
				id="sig-1"
				style={{
					borderCollapse: "collapse",
					borderRadius: "16px",
				}}
			>
				<tr>
					<td
						width="64"
						style={{
							verticalAlign: "middle",
						}}
					>
						<a href={`mailto:${personEmail}`}>
							<img
								alt="Trait Media"
								width="64"
								style={{
									display: "block",
									width: "64px",
									height: "64px",
								}}
								src={`${absPath}email-signature-${personAvatar}.png`}
							/>
						</a>
					</td>
					<td width="16"></td>
					<td
						style={{
							fontFamily: "sans-serif",
							fontSize: "12px",
							verticalAlign: "middle",
							lineHeight: "160%",
						}}
					>
						<b
							style={{
								display: "block",
								fontSize: "1.25em",
								marginBottom: "3px",
							}}
						>
							{personName}
							<span style={{ color: "#25ce95" }}></span>
						</b>
						Tel:{" "}
						<a
							style={{ color: "inherit", textDecoration: "none" }}
							href={`tel:${personPhone}`}
						>
							{personPhone}
						</a>
						&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
						<a
							href="https://trait.media"
							style={{ color: "inherit", textDecoration: "none" }}
						>
							https://trait.media
						</a>
						<br />
						<a href={`mailto:${personEmail}`}>
							<img
								alt="Trait Media"
								width="64"
								style={{
									display: "block",
									width: "64px",
									height: "15px",
									marginTop: "3px",
								}}
								src={`${absPath}email-signature-logo.png`}
							/>
						</a>
					</td>
					<td width="16"></td>
				</tr>
				<tr>
					<td height="16"></td>
				</tr>
			</table>
			<br />
		</>
	);
}
