import React from "react";
import "./assets/css/main.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/Home.tsx";
import JoinPage from "./pages/Join.tsx";
import EmailSignature from "./pages/EmailSignature.tsx";

function App() {
	return (
		<div className="App" id="page">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/join-us" element={<JoinPage />} />
					<Route path="/email-signature" element={<EmailSignature />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
